const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

const redirect_uri =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:9000";


module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  appName: "MYBE People",
  appVersion: 0.1,
  appVersionMessage: "System Created",

  //Redirect url for authing
  redirect_uri: redirect_uri,
};
